import { Button } from "@getbread/dough";
import { styled } from "@mui/material/styles";

export const LinkButton = styled(Button)`
	padding: 0;
	margin: 0;
	min-width: 0;
	min-height: 0;
`;

LinkButton.defaultProps = {
	variant: "text",
};
