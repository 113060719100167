import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import { Box } from "@getbread/dough";
import CircularProgress from "@mui/material/CircularProgress";

import { FCWithChildren } from "../api-client/resources/types/sharedTypes";
import { Authenticated } from "../components/Authenticated";
import { useAuthentication } from "../components/AuthenticationProvider";
import { RefreshTimer } from "../components/AuthenticationProvider/RefreshTimer";
import { GenericErrorBoundary } from "../components/errors/GenericErrorBoundary";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { InvalidJwtErrorBoundary } from "../components/InvalidJwtErrorBoundary/InvalidJwtErrorBoundary";
import { PageContainer } from "../components/PageContainer/PageContainer";
import { PageHead } from "../components/PageHead/PageHead";
import { stdSpacing } from "../global.style";

// Pages need to be default exports for code-splitting
const LoginPage = lazy(() => import("./LoginPage/LoginPage"));
const AccountPage = lazy(() => import("./AccountPage/AccountPage"));
const LoansPage = lazy(() => import("./LoansPage/LoansPage"));
const DisclosurePage = lazy(() => import("./DisclosurePage/DisclosurePage"));
const TermsAndConditionsPage = lazy(() => import("./DisclosurePage/TermsAndConditionsPage"));
const IdentityVerificationPage = lazy(
	() => import("./IdentityVerificationPage/IdentityVerificationPage"),
);

const Logout: React.FC = () => {
	const { logOut } = useAuthentication();
	useEffect(() => {
		logOut();
	}, [logOut]);
	return <Navigate to="/" />;
};

const FullRouteWrapper: FCWithChildren = ({ children }) => {
	return (
		<>
			<Header />
			<PageContainer>
				<GenericErrorBoundary>
					<InvalidJwtErrorBoundary>
						<Suspense
							fallback={
								<Box marginTop={stdSpacing.xxl}>
									<CircularProgress />
								</Box>
							}
						>
							<div id="main-content">{children}</div>
						</Suspense>
					</InvalidJwtErrorBoundary>
				</GenericErrorBoundary>
			</PageContainer>
			<Footer />
		</>
	);
};

const PartialRouteWrapper: FCWithChildren = ({ children }) => (
	<InvalidJwtErrorBoundary>
		<Suspense
			fallback={
				<Box marginTop={stdSpacing.xxl}>
					<CircularProgress />
				</Box>
			}
		>
			{children}
		</Suspense>
	</InvalidJwtErrorBoundary>
);

const HandleHomeRouteRender: React.FC = () => {
	const { loggedIn } = useAuthentication();

	if (loggedIn) {
		return <Navigate to="/loans" />;
	}

	return <Navigate to="/login" />;
};

const ReactRouter: React.FC = () => (
	<Router>
		<RefreshTimer />
		<Routes>
			<Route path="/" element={<HandleHomeRouteRender />} />
			<Route
				path="/login"
				element={
					<FullRouteWrapper>
						<LoginPage />
					</FullRouteWrapper>
				}
			/>
			<Route path="/logout" element={<Logout />} />
			<Route
				path="/account"
				element={
					<Authenticated>
						<FullRouteWrapper>
							<AccountPage />
						</FullRouteWrapper>
					</Authenticated>
				}
			/>
			<Route
				path="/loans/*"
				element={
					<Authenticated>
						<FullRouteWrapper>
							<LoansPage />
						</FullRouteWrapper>
					</Authenticated>
				}
			/>
			<Route
				path="/upload-id"
				element={
					<Authenticated>
						<FullRouteWrapper>
							<IdentityVerificationPage />
						</FullRouteWrapper>
					</Authenticated>
				}
			/>
			<Route
				path="/disclosure/payment-agreement/:loanId/views/:viewType"
				element={
					<Authenticated>
						<PartialRouteWrapper>
							<DisclosurePage />
						</PartialRouteWrapper>
					</Authenticated>
				}
			/>
			<Route
				path="/disclosure/payment-agreement/:loanId/acknowledgements/name/:viewType/target-id/:targetId"
				element={
					<Authenticated>
						<PartialRouteWrapper>
							<DisclosurePage />
						</PartialRouteWrapper>
					</Authenticated>
				}
			/>
			<Route
				path="/disclosure/verification-service"
				element={
					<Authenticated>
						<PartialRouteWrapper>
							<TermsAndConditionsPage />
						</PartialRouteWrapper>
					</Authenticated>
				}
			/>
			<Route path="*" element={<Navigate to="/loans" />} />
		</Routes>
	</Router>
);

/** Route definitions */
export const RootPage: React.FC = () => (
	<>
		<PageHead />
		<ReactRouter />
	</>
);
