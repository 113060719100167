import { useCallback, useState } from "react";

import { getItem, setItem } from "./localStorage";

// inspired by https://usehooks.com/useLocalStorage/
function useLocalStorage(key: string, initialValue: string | number) {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = getItem(key);
			return item !== null ? item : initialValue;
		} catch (error) {
			return initialValue;
		}
	});

	const setValue = useCallback(
		(value: string | number | ((v: string) => string | number)) => {
			try {
				const valueToStore = value instanceof Function ? value(storedValue) : value;

				setStoredValue(valueToStore);

				setItem(key, valueToStore);
			} catch (e) {} // eslint-disable-line no-empty
		},
		[key, storedValue],
	);

	return [storedValue, setValue];
}

export { useLocalStorage };
