import { ReactNode, useEffect, useState } from "react";

// adapted from https://medium.com/trabe/delayed-render-of-react-components-3482f8ad48ad
export const useDelayedRender = (delay: number) => {
	const [delayed, setDelayed] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => setDelayed(false), delay);
		return () => clearTimeout(timeout);
	}, [delay]);

	return (callback: () => any) => !delayed && callback();
};

export const DelayedRender = ({
	delay,
	children,
}: {
	delay: number;
	children: ReactNode | ReactNode[];
}) => useDelayedRender(delay)(() => children);
