// This module is mocked for Jest in setupEnv.js since Jest does not support import.meta
// Make sure the mock is updated if you make changes here

import { LoggerClient } from "@getbread/wheat-logger";

import { getConfig } from "./getConfig.ts";

export const isProductionMode = (): boolean => import.meta.env.PROD;
export const getLogLevel = (): keyof LoggerClient =>
	import.meta.env.VITE_LOG_LEVEL ?? getConfig("loglevel");

export const isDevelopmentMode = (): boolean => import.meta.env.DEV;
export const isMirageEnabled = (): boolean =>
	import.meta.env.VITE_API_MOCK_MIRAGE === "true" && !import.meta.env.VITE_API_URL;
