/**
 * adapted from https://github.com/auth0/jwt-decode
 */
function parseJwt(token: string) {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join(""),
	);

	return JSON.parse(jsonPayload);
}

export function propertyFromJwt(token: string, property: string) {
	if (!token) {
		return null;
	}

	let parsed;
	try {
		parsed = parseJwt(token);
	} catch (e) {
		throw new Error(`Error decoding token: ${token}`);
	}

	if (!(property in parsed)) {
		throw new Error(`${property} not found in token`);
	}

	return parsed[property];
}
