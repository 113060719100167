export type ConfigName = keyof typeof AppConfig | keyof typeof BuildConfig;

export function getConfig<T extends ConfigName>(
	configName: T,
): (typeof AppConfig & typeof BuildConfig)[T] {
	if (typeof AppConfig !== "undefined" && typeof BuildConfig !== "undefined") {
		const Config = { ...AppConfig, ...BuildConfig };
		if (configName in Config) {
			return Config[configName];
		}
	}

	throw new Error(`Unknown config: ${configName}`);
}

export type StructureConfigName = keyof typeof AppConfig.structure;

export function getStructureConfig(configName: StructureConfigName) {
	const structure = getConfig("structure");

	if (configName in structure) {
		return structure[configName];
	}

	throw new Error(`Unknown config: structure.${configName}`);
}
