import { Locale, supportedLocales } from "@getbread/dough";
import { uniq } from "ramda";

import { getConfig } from "./getConfig";
import { getItem } from "./localStorage";

export const localeStorageKey = `${getConfig("tenant")}-members-portal-locale`;

export const locales = getConfig("locales") as string[];

export const defaultLocale = getConfig("defaultlocale") as string;

const isSupportedDoughLocale = (locale?: string): locale is Locale => {
	if (!locale) return false;
	return !!Object.values(supportedLocales).find((supportedLocale) => supportedLocale === locale);
};

/**
 * Returns a browser locale or the tenant's default locale
 */
export function getBrowserLocale(acceptedLocales = locales): Locale {
	// normalize accepted and navigator locales to lowercase
	const accepted = acceptedLocales.map((a) => a.toLowerCase());
	const nav = window.navigator.languages.map((a) => a.toLowerCase());

	// for each browser locale, filter out unsupported ones
	const langCodes = nav
		.map((l) => {
			// Note that navigator codes are not only in the `xx-XX`` format - Safari can just give
			// the top level language code (`xx`), hence the indexOf instead of a straight comparison.
			// In this case we'll just return the first accepted locale that includes that top level language.
			return accepted.find((e) => e.indexOf(l) !== -1);
		})
		.filter((l) => l !== undefined);

	const codes = uniq(langCodes);

	const selectedLocale = codes?.[0] || defaultLocale;

	if (!isSupportedDoughLocale(selectedLocale)) {
		throw new Error(
			`Locale '${selectedLocale}' is not supported by the dough library. Supported locales include ${Object.values(
				supportedLocales,
			).join(", ")}`,
		);
	}

	return selectedLocale;
}

const isAcceptableLocale = (userLocale: string, acceptedLocales = locales): userLocale is Locale =>
	!!acceptedLocales.find((e) => e.indexOf(userLocale) !== -1) &&
	isSupportedDoughLocale(userLocale);

export function getLocale(): Locale {
	// a locale in localStorage is the authoritative locale
	const item = getItem(localeStorageKey);

	if (isAcceptableLocale(item)) {
		return item;
	}

	// if we were to detect locale based on query string, for example, that would go here

	// finally, fall back to a browser locale if available
	return getBrowserLocale();
}
