import { NavLink } from "react-router-dom";

import { pxToRem } from "@getbread/dough";
import { styled } from "@mui/material/styles";

import { LinkButton } from "../shared/LinkButton";

const sharedNavStyles = `
	font-size: ${pxToRem(16)};
	font-weight: 500;
	margin-right: var(--space-s);
	padding: var(--space-s);
	text-decoration: none;
`;

const HeaderExteriorLink = styled("a")`
	${sharedNavStyles};
	color: ${({ theme }) => theme.palette.text.primary};

	&:hover {
		background-color: ${({ theme }) => theme.palette.background.accentLight};
		border-radius: 2px;
	}
`;

const HeaderLink = styled(NavLink)`
	${sharedNavStyles};
	color: ${({ theme }) => theme.palette.text.primary};

	&.active {
		color: ${({ theme }) => theme.palette.primary.main};
	}

	&:hover {
		background-color: ${({ theme }) => theme.palette.background.accentLight};
		border-radius: 2px;
	}
`;

const HeaderLinkButton = styled(LinkButton)`
	font-size: ${pxToRem(16)};
	text-decoration: none;
	color: ${({ theme }) => theme.palette.text.primary};
	padding: var(--space-l);
	text-align: center;
`;

export { HeaderLink, HeaderExteriorLink, HeaderLinkButton };
