import {
	buyerLogin,
	BuyerLoginBody,
	refreshBuyerToken,
	sendCode,
	SendCodeBody,
	verifyCode,
} from "@getbread/api-client-auth-v2";
import { AxiosResponse } from "axios";
import { sub } from "date-fns";

import { tracking } from "../tracking/tracking";
import { getConfig } from "../utils/getConfig";
import { LoginResponse } from "./types";

type DeliveryMethod = "SMS" | "EMAIL";

class AuthApiError extends Error {
	constructor(response: AxiosResponse) {
		super(response.statusText);
	}
}

class RetryApiError extends AuthApiError {
	remainingRetries: number;

	constructor(response: AxiosResponse, attempts: number, maxAttempts: number) {
		super(response);

		this.remainingRetries = Number(maxAttempts) - Number(attempts);
	}
}

class InvalidInputError extends AuthApiError {}

class InvalidJwtApiError extends Error {}

class DuplicateEmailError extends AuthApiError {}

class DuplicatePhoneError extends AuthApiError {}

class UnauthenticatedBuyerNotFoundError extends AuthApiError {}

const filterError = async (resp: AxiosResponse) => {
	const body = resp?.data;

	if (!body) {
		throw new AuthApiError(resp);
	}

	switch (body.reason) {
		case "Invalid_Token":
			throw new RetryApiError(resp, body.metadata.attempts, body.metadata.max_attempts);
		case "Unauthenticated":
			throw new UnauthenticatedBuyerNotFoundError(resp);
		case "Unauthenticated_Buyer_Not_Found": // legacy
		case "Unauthenticated_Buyer_Mismatch": // legacy
		case "Unauthenticated_Retry_With_PII":
			throw new InvalidInputError(resp);
		case "Existing_Buyer_Email":
			throw new DuplicateEmailError(resp);
		case "Existing_Buyer_Phone":
			throw new DuplicatePhoneError(resp);
		default:
			throw new AuthApiError(resp);
	}
};

export {
	AuthApiError,
	RetryApiError,
	InvalidJwtApiError,
	InvalidInputError,
	DuplicateEmailError,
	DuplicatePhoneError,
	UnauthenticatedBuyerNotFoundError,
};

function buildSendTokenBody(
	deliveryMethod: DeliveryMethod,
	phone: string | undefined,
	email: string,
	acceptedAt: Date,
	languagePreference: string,
) {
	const configuredDisclosures =
		getConfig("logindisclosures")[deliveryMethod.toLowerCase() as Lowercase<DeliveryMethod>];

	const returnVal: SendCodeBody = {
		phone: deliveryMethod === "SMS" ? phone : undefined,
		email,
		deliveryMethod,
		languagePreference,
		disclosures: configuredDisclosures.map((d) => ({ type: d, acceptedAt })),
		uat: undefined,
	};

	return returnVal;
}

/**
 * Sends an authorization token to a user via either phone or email. Recieves a reference ID that can be sent to `authorize`
 * along with the token to verify a user.
 * v2: /api/auth/send-code, -refID+referenceID
 */
export const sendToken = async (
	deliveryMethod: DeliveryMethod,
	{ phone, email }: { phone?: string; email: string },
	languagePreference: string,
) => {
	const acceptedAt = sub(new Date(), { days: 1 });

	const body = buildSendTokenBody(deliveryMethod, phone, email, acceptedAt, languagePreference);

	if (getConfig("sandbox")) {
		switch (deliveryMethod) {
			case "SMS":
				body.uat = { auth: { phoneType: "VOIP", token: "1234" } };
				break;
			case "EMAIL":
				body.uat = { auth: { token: "1234" } };
				break;
			default:
				break;
		}
	}

	try {
		return (await sendCode(body)).data.referenceID;
	} catch (error) {
		return filterError(error as AxiosResponse);
	}
};

/**
 * Takes a reference ID and token and authenticates (logs in) a user
 */
export const login = async (values: BuyerLoginBody): Promise<LoginResponse> => {
	try {
		const body = (await buyerLogin(values)).data;
		tracking.trackSuccessfulLogin();
		return {
			jwt: body.token,
			expiresAt: body.tokenExpiresAt,
			buyerId: body.buyerID,
		};
	} catch (error) {
		tracking.trackLoginFailure();
		return filterError(error as AxiosResponse);
	}
};

/**
 * Takes a reference ID and token and authenticates a *new* number
 */
export const authenticate = async (refID: string, token: string) => {
	try {
		await verifyCode({ code: token, referenceID: refID });
		return true;
	} catch (error) {
		return filterError(error as AxiosResponse);
	}
};

/**
 * Generates a new JWT with an extended expiry time
 */
export const refresh = async () => {
	try {
		const body = (await refreshBuyerToken()).data;
		return { jwt: body.token, expiresAt: body.tokenExpiresAt };
	} catch (error) {
		return filterError(error as AxiosResponse);
	}
};
