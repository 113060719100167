import { createContext, useContext, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { FCWithChildren } from "../../api-client/resources/types/sharedTypes";
import { logger } from "../../logger";
import { propertyFromJwt } from "../../utils/jwt";
import { useLocalStorage } from "../../utils/useLocalStorage";

export const jwtStorageKey = "bread-members-portal-jwt";

export type AuthenticationContextValue = {
	loggedIn: boolean;
	logIn: (jwt: string, newBuyerId: string) => void;
	logOut: () => void;
	buyerId: string;
	refresh: (jwt: string) => void;
	setMerchantName: (merchant: string) => void;
	selectedMerchant?: string;
};

const AuthenticationContext = createContext<AuthenticationContextValue | undefined>(undefined);

const AuthenticationProvider: FCWithChildren = ({ children }) => {
	// if a JWT exists on this device, default to that
	const [token, setToken] = useLocalStorage(jwtStorageKey, "");

	// if we have a JWT, extract the buyer ID from that
	const [buyerId, setBuyerId] = useState(propertyFromJwt(token, "buyerID"));

	const [selectedMerchant, setMerchant] = useState<string>();

	const queryClient = useQueryClient();

	const setMerchantName = (merchant: string) => {
		setMerchant(merchant);
	};

	const contextValue = useMemo(() => {
		const logIn = (jwt: string, newBuyerId: string) => {
			setBuyerId(newBuyerId);
			setToken(jwt);
		};

		const logOut = () => {
			setBuyerId(null);
			setToken("");
			queryClient.clear();
			window.sessionStorage.clear();
		};

		const refresh = (jwt: string) => {
			setToken(jwt);
		};

		const loggedIn = !!token;
		logger.addCommonProperties({ buyerId: loggedIn ? buyerId : null });

		return {
			loggedIn,
			logIn,
			logOut,
			buyerId,
			refresh,
			setMerchantName,
			selectedMerchant,
		};
	}, [buyerId, queryClient, selectedMerchant, setToken, token]);

	return (
		<AuthenticationContext.Provider value={contextValue}>
			{children}
		</AuthenticationContext.Provider>
	);
};

function useAuthentication(): AuthenticationContextValue {
	const context = useContext(AuthenticationContext);

	if (!context) {
		throw new Error("useAuthentication must be used within a context");
	}

	return context;
}

export { AuthenticationProvider, useAuthentication };
