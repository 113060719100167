import { pxToRem } from "@getbread/dough";
import styled, { css } from "styled-components";

const IconBase = styled("div")<{ open: boolean }>`
	margin: var(--space-s) 0;
	padding: var(--space-s) 0;

	& div,
	& div::before,
	& div::after {
		background-color: rgb(0 0 0 / 100%);
		border-radius: ${pxToRem(1)};
		position: relative;
		height: ${pxToRem(2)};
		width: ${pxToRem(20)};
	}

	& div {
		transition: background-color 0s 0.125s;
	}

	& div::before,
	& div::after {
		content: "";
		left: 0;
		position: absolute;
		transition-duration: 0.125s, 0.125s;
		transition-delay: 0.125s, 0s;
	}

	& div::before {
		top: -${pxToRem(6)};
		transition: top 0.125s 0.125s, transform 0.125s 0s;
	}

	& div::after {
		bottom: -${pxToRem(6)};
		transition: bottom 0.125s 0.125s, transform 0.125s 0s;
	}

	${(props) =>
		props.open &&
		css`
			& div {
				background-color: rgb(0 0 0 / 0%);
			}

			& div::before {
				transition: top 0.125s 0s, transform 0.125s 0.125s;
				top: 0;
				transform: rotate(45deg);
			}

			& div::after {
				transition: bottom 0.125s 0s, transform 0.125s 0.125s;
				bottom: 0;
				transform: rotate(-45deg);
			}
		`}
`;

const Icon: React.FC<{ open: boolean }> = ({ open }) => {
	return (
		<IconBase open={open}>
			<div />
		</IconBase>
	);
};

export { Icon };
