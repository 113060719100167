/**
 * Detect whether localStorage or sessionStorage is supported and available
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */
export function storageAvailable(type: "localStorage" | "sessionStorage") {
	const storage: Storage = window[type];
	try {
		const x = "__storage_test__";
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return (
			e instanceof DOMException &&
			// everything except Firefox
			(e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === "QuotaExceededError" ||
				// Firefox
				e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage.length !== 0
		);
	}
}

const memoryStorageFallback: { [key: string]: any } = {};

export function getItem(key: string) {
	if (storageAvailable("localStorage")) {
		try {
			const item = window.localStorage.getItem(key);

			return item ? JSON.parse(item) : null;
		} catch (e) {
			return null;
		}
	}

	return memoryStorageFallback[key];
}

export function setItem(key: string, value: any) {
	if (storageAvailable("localStorage")) {
		window.localStorage.setItem(key, JSON.stringify(value));
	}

	memoryStorageFallback[key] = value;
}

export function removeItem(key: string) {
	if (storageAvailable("localStorage")) {
		window.localStorage.removeItem(key);
	}

	delete memoryStorageFallback[key];
}
