import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { pxToRem } from "@getbread/dough";
import { styled } from "@mui/material/styles";

import { useLocalePublicPath } from "../../utils/usePublicPath";
import { useLocale } from "../LocaleProvider";

const LogoImg = styled((props) => <img alt={props.alt} {...props} />)`
	max-width: ${pxToRem(220)};
`;

export const Logo = styled(({ className }: { className?: string }) => {
	const intl = useIntl();
	const { locale } = useLocale();
	const publicPath = useLocalePublicPath();

	return (
		<Link to="/" className={className} data-testid="header-logo-link">
			<LogoImg
				src={`${publicPath}/logo.svg`}
				alt={intl.formatMessage({
					defaultMessage: "Member Portal Home",
				})}
				data-testid={`logo-${locale}`}
			/>
		</Link>
	);
})`
	grid-column: 1/4;
`;
