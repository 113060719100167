import { HelmetProvider } from "react-helmet-async";

import {
	breadTheme,
	ModalProvider,
	payPlanTheme,
	threeDotDough,
	ToastProvider,
} from "@getbread/dough";
import { Theme } from "@mui/material";

import { ApiClientProvider } from "./apiClientProvider";
import { AppContainer } from "./components/AppContainer/AppContainer.style";
import { AuthenticationProvider } from "./components/AuthenticationProvider";
import { GlobalErrorContext } from "./components/errors/GlobalErrorContext";
import { LaunchDarklyProvider } from "./components/LaunchDarklyProvider";
import { LocaleProvider } from "./components/LocaleProvider";
import { ThemeProvider } from "./global.style";
import { RootPage } from "./pages/RootPage";
import { getConfig } from "./utils/getConfig";

const themeName = getConfig("themename");

const themes: Record<string, Theme> = {
	breadTheme,
	payPlanTheme,
	threeDotDough,
};

const App = () => {
	return (
		<LaunchDarklyProvider>
			<LocaleProvider>
				<ThemeProvider theme={themes[`${themeName}`]}>
					<HelmetProvider>
						<ApiClientProvider>
							<AuthenticationProvider>
								<ToastProvider>
									<ModalProvider>
										<AppContainer id="app">
											<RootPage />
											<GlobalErrorContext />
										</AppContainer>
									</ModalProvider>
								</ToastProvider>
							</AuthenticationProvider>
						</ApiClientProvider>
					</HelmetProvider>
				</ThemeProvider>
			</LocaleProvider>
		</LaunchDarklyProvider>
	);
};

export { App };
