import { FormattedMessage } from "react-intl";

import { Button, pxToRem } from "@getbread/dough";
import styled from "styled-components";

import { useAuthentication } from "../AuthenticationProvider";
import { LoggedOutNavigation } from "../Navigation/LoggedOutNavigation";
import { Navigation } from "../Navigation/Navigation";
import { Logo } from "../shared/Logo";
import { HeaderBorder, HeaderContainer, NavContainer } from "./Header.style";

const SkipLinkButton = styled(Button)`
	position: absolute;
	top: 0;
	left: 0;
	transform: translateY(-100%);
	background-color: white;
	color: black;
	min-height: auto;
	padding: ${pxToRem(5)};
	border-radius: 0%;

	:focus-visible {
		transform: translateY(0%);
		margin: ${pxToRem(5)};
		background-color: white;
		color: black;
		outline-offset: 0;
	}
`;

export const Header = () => {
	const { loggedIn } = useAuthentication();

	return (
		<>
			<HeaderContainer className="mui-fixed">
				<SkipLinkButton
					href="#main-content"
					aria-label="skip-link-button"
					data-testid="skip-link-button"
				>
					<FormattedMessage defaultMessage="Skip to main content" />
				</SkipLinkButton>
				<Logo />
				<NavContainer>{loggedIn ? <Navigation /> : <LoggedOutNavigation />}</NavContainer>
			</HeaderContainer>
			<HeaderBorder />
		</>
	);
};
