import { Navigate, useLocation } from "react-router-dom";

import { FCWithChildren } from "../../api-client/resources/types/sharedTypes";
import { useAuthentication } from "../AuthenticationProvider";

const Authenticated: FCWithChildren = ({ children }) => {
	const location = useLocation();
	const { loggedIn } = useAuthentication();

	if (!loggedIn) {
		return <Navigate to="/login" state={{ from: location }} />;
	}

	return <>{children}</>;
};

export { Authenticated };
