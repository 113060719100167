import { Helmet } from "react-helmet-async";

import { getConfig } from "../../utils/getConfig";
import { useLocalePublicPath, usePublicPath } from "../../utils/usePublicPath";

export const PageHead: React.FC = () => {
	const faviconVersion = "2";
	const isBreadTenant = getConfig("tenant") === "bread";

	const publicPath = useLocalePublicPath();
	const tenantPath = usePublicPath();
	return (
		<Helmet>
			<link rel="icon" href={`${publicPath}/favicons/favicon.ico?v=${faviconVersion}`} />
			<link
				rel="icon"
				href={`${publicPath}/favicons/icon.svg?v=${faviconVersion}`}
				type="image/svg+xml"
			/>
			<link
				rel="apple-touch-icon"
				href={`${publicPath}/favicons/apple-touch-icon.png?v=${faviconVersion}`}
			/>
			<link
				rel="manifest"
				href={`${publicPath}/favicons/site.webmanifest?v=${faviconVersion}`}
			/>
			<link rel="stylesheet" href={`${tenantPath}/fonts/fonts.css`} />
			<meta name="mobile-web-app-capable" content="yes" />
			<meta name="theme-color" content="#fff" />
			{isBreadTenant && (
				<meta
					name="description"
					content="You can sign in to make a payment on your Buy Now Pay Later account or Bread Loan. Easily manage your account from our Member Portal."
				/>
			)}
		</Helmet>
	);
};
