import { FormattedMessage } from "react-intl";

import { Link, pxToRem } from "@getbread/dough";
import { styled } from "@mui/material/styles";

import { getConfig } from "../../utils/getConfig";
import { LocaleDropdown } from "../LocaleDropdown/LocaleDropdown";
import { useLocale } from "../LocaleProvider";

export const LocaleContainer = styled("div")`
	display: flex;
	width: ${pxToRem(55)};
`;

const FaqLink = styled(Link)`
	font-size: ${pxToRem(16)};
	align-self: center;
	margin-left: var(--space-s);
	font-weight: 500;
`;

export const LoggedOutNavigation: React.FC = () => {
	const hasMultipleLanguages = getConfig("locales").length > 1;
	const { locale, changeLocale } = useLocale();

	const faqLinks = getConfig("faqlinks").general;
	const faqLink = faqLinks[locale];

	return (
		<>
			{hasMultipleLanguages && (
				<LocaleContainer>
					<LocaleDropdown selected={locale} selectLocale={changeLocale} />
				</LocaleContainer>
			)}
			<FaqLink target="_" href={faqLink} data-testid="faq-link">
				<FormattedMessage defaultMessage="FAQs" />
			</FaqLink>
		</>
	);
};
