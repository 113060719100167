import { createRoot } from "react-dom/client";

import { App } from "./App";
import { logger } from "./logger";
import { AdobeAnalytics } from "./tracking/adobeAnalytics";
import { loadDecibel } from "./tracking/decibel";
import { isMirageEnabled } from "./utils/importMeta";

if (isMirageEnabled()) {
	logger.info("Using mock data for API requests!");
	await import("./api-client/server/server");
}

AdobeAnalytics.init();

loadDecibel();

const container = document.createElement("div");
document.body.append(container);

const root = createRoot(container);
root.render(<App />);
